import Typography from "@material-ui/core/Typography";
import React, { useEffect, useState } from "react";
import RightArrowWhite from "../../assets/Icons/accordian_white.svg";
import RightArrow from "../../assets/Icons/go.svg";
import Clm2Img from "../../assets/Icons/mail.svg";
import Clm1Img from "../../assets/Icons/phone.svg";
import BackArrowIcon from "../../assets/Images/accordian.svg";
import BannerBgImage from "../../assets/Images/ArticleWhitePaper/Articles_banner.png";
import ogimage from "../../assets/Images/ogImages/article@2x.png";
import Button from "../../components/Button";
import Layout from "../../components/layouts/layout";
import { BASE_SITE_URL } from "../../shared/constants";
import Box from "../../templates/Box";
import ImageBanner from "../../templates/ImageBanner";
import Infoview from "../../templates/InfoView";
import Miniview from "../../templates/MiniView";
import Megaview from "../../templates/MegaView";
import Checkbox from "@material-ui/core/Checkbox";
import CircleChecked from "@material-ui/icons/CheckCircleOutline";
import CircleCheckedFilled from "@material-ui/icons/CheckCircle";
import CircleUnchecked from "@material-ui/icons/RadioButtonUnchecked";
import TwoColumnContactInfo from "../../templates/TwoColumnContactInfo";
import { makeStyles } from "@material-ui/core/styles";
import GridListTile from "@material-ui/core/GridListTile";
import GridListTileBar from "@material-ui/core/GridListTileBar";
import IconButton from "@material-ui/core/IconButton";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import culture from "../../assets/Images/category/CultureDiversity_Inclusion.jpg";
import Data from "../../assets/Images/category/Data.jpg";
import EmergingTechnologiesBusiness from "../../assets/Images/category/EmergingTechnologies_Business.jpg";
import HumanresourcesTalentCareerAdvice from "../../assets/Images/category/HumanresourcesTalent_CareerAdvice.jpg";
import LeadershipStyles from "../../assets/Images/category/LeadershipStyles.jpg";
import MethodologiesSkillsDevelopment from "../../assets/Images/category/Methodologies_SkillsDevelopment.jpg";
import NorthernPowerhouse from "../../assets/Images/category/NorthernPowerhouse.jpg";
import StartupsDisruptors from "../../assets/Images/category/Startups_Disruptors.jpg";
import { Grid } from "@material-ui/core";
import DataService from "../../services/api/data_services";
import { Link } from "gatsby";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    width: 500,
    height: 450,
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: "translateZ(0)",
  },
  titleBar: {
    background:
      "linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, " +
      "rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
  },
  icon: {
    color: "white",
  },
  tile: {
    borderRadius: "3%",
  },
  titleBarRoot: {
    background: "none",
  },
  titleBarTitle: {
    color: "white",
    fontSize: "20px",
    textAlign: "center",
    marginBottom: "50px",
  },
  container: {
    position: "relative",
  },
  colorSecondary: {
    color: "white",
  },
  checkboxPosition: {
    position: "absolute",
    right: "5px",
    top: "5px",
    color: "white",
  },
}));
const tileData = [
  {
    img: culture,
    title: (
      <>
        Culture,Diversity & <br /> Inclusion
      </>
    ),
    slug_type: "culture-diversity-inclusion",
  },

  {
    img: StartupsDisruptors,
    title: (
      <>
        Startups & <br /> Disruptors
      </>
    ),
    slug_type: "startups-disruptors",
  },
  {
    img: HumanresourcesTalentCareerAdvice,
    title: (
      <>
        Human resources,Talent <br /> & Career Advice
      </>
    ),
    slug_type: "human-resources-talent-career-advice",
  },
  {
    img: LeadershipStyles,
    title: (
      <>
        Leadership <br /> Styles
      </>
    ),
    slug_type: "leadership-styles",
  },
  {
    img: MethodologiesSkillsDevelopment,
    title: (
      <>
        Methodologies & <br /> Skills Development
      </>
    ),
    slug_type: "methodologies-skills-development",
  },
  {
    img: EmergingTechnologiesBusiness,
    title: (
      <>
        Emerging Technologies <br /> & Business
      </>
    ),
    slug_type: "emerging-technologies-business",
  },
  {
    img: Data,
    title: "Data",
    slug_type: "data",
  },
  {
    img: NorthernPowerhouse,
    title: (
      <>
        Northern <br /> Powerhouse
      </>
    ),
    slug_type: "northern-powerhouse",
  },
];
const Podcast = (props) => {
  const classes = useStyles();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(9);
  const [articleData, setArticleData] = useState([]);
  const [articleRawData, setArticleRawData] = useState([]);
  const [isHover, setIsHover] = useState(false);
  const [articleTag, setArticleTag] = useState([]);
  const [selectedTag, setSelectedTag] = useState("");
  const [radioValue, setValue] = useState([]);
  const urlParams =
    typeof window !== `undefined` &&
    new URLSearchParams(window.location.search);
  const myParam = typeof window !== `undefined` && urlParams.get("tag");

  const [megaviewData, setMegaViewData] = useState([]);
  const [checked, setChecked] = React.useState(true);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const handleMenu = (e, name, index) => {
    // setValue((radioValue) => [...radioValue, name]);
    // updateList(list.filter(item => item.name !== name));
    const likes = radioValue;
    //check to see if person has already liked
    if (likes.includes(name)) {
      //user has already liked. Choose one option (1 or 2)

      //Option 1: create a new like array without the current user
      const newLikes = likes.filter((like) => like !== name);

      setValue(newLikes);
      return;
    } else {
      setValue((radioValue) => [...radioValue, name]);
    }
  };

  useEffect(() => {
    DataService.getData(
      `/admin/api/podcast?&type=White Paper&podcast_mtype[]=${
        radioValue.length ? radioValue : "all"
      }`
    ).then((response) => {
      setMegaViewData(response);
      // setArticleRawData(response);
      // if(page === 1){
      //   setPage(2);
      // }
    });
  }, [radioValue]);
  useEffect(() => {
    DataService.getData(`/admin/api/podcast?type=all&component=min_view`).then(
      (response) => {
        setArticleTag(response.all_tags);
        setSelectedTag(response.selected_tag);
        setArticleData(response.data);
        setArticleRawData(response);
      }
    );
  }, [page, myParam, limit]);

  const toggleHover = () => {
    setIsHover(!isHover);
  };

  /**
   * TwoColumnContactInfo
   */
  const TwoColumnContactInfoData = {
    Clm1Img: Clm1Img,
    Clm1Title: "Phone:",
    Clm1SubTitle: "+44 (0) 161 706 7983",
    Clm2Img: Clm2Img,
    Clm2Title: "Email:",
    Clm2SubTitle: "hello@digiryte.com",
  };

  /**
   * Box component
   */

  const BoxData = {
    boxBtn1Width: "230px",
    boxBtn1Height: "46px",
    boxBtn1variant: "contained",
    boxBtn1color: "primary",
    boxBtn1label: "CAREER",
    tip: "CAREER",
    tooltip: "yes",
    boxBtn1Title: "Join our team",
    Clm2SubTitle: "careers@digiryte.com",
    boxBtn1SubTitle:
      "If you're a designer or a developer who loves to challenge conventions, make the ryte move!",
    boxBtn2Width: "230px",
    boxBtn2Height: "46px",
    boxBtn2variant: "contained",
    boxBtn2color: "primary",
    boxBtn2label: "GET IN TOUCH",
    boxBtn2Title: "Let’s talk",
    tip1: "GET IN TOUCH",
    boxBtn2SubTitle:
      "Have you got an exciting project that you'd like to discuss?",
    route1: "/contact-us",
    route2: "/contact-us",
  };

  const ImageBannerData = {
    bnrImg: BannerBgImage,
    bnrImgTitle: "Podcast",
    subtitleclassname: "",
    bnrImgSubTitle: "All Resources",
    bnrImgClassName: "insightsbannerimg",
    servicesColumn1: {
      data: [
        {
          service: "Articles",
          link: "/insights/articles",
        },
      ],
    },
    servicesColumn2: {
      data: [
        {
          service: "White Papers",
          link: "/insights/white-papers",
        },
      ],
    },
    servicesColumn3: {
      data: [
        {
          service: "Podcast",
          link: "/insights/podcast",
        },
      ],
    },
  };
  /**
   * Infoview
   */
  const InfoviewData = {
    InfoviewTitle: (
      <div className="mbl-br-hide">
        <span className="red-line3letter">Wel</span>come-note Header
      </div>
    ),
    tag: "h2",
    width: "87px",
    InfoviewContent: (
      <>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
        veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
        commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
        velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
        occaecat cupidatat non proident, sunt in culpa qui officia deserunt
        mollit anim id est laborum.
      </>
    ),
  };
  return (
    <Layout
      seo={{
        title: "Insights | Podcast |",
        ogtitle: "Insights | Podcast |",
        description: "Our hub of knowledge to help your business succeed",
        ogimage: ogimage,
        ogurl: `${BASE_SITE_URL}/insights/podcast`,
      }}
    >
      <ImageBanner imageBannerData={ImageBannerData} />
      <div className="mt-70 body-container">
        <Button
          icon={
            <img
              className="effect-btn-svgicon"
              src={BackArrowIcon}
              alt="click"
            />
          }
          customClassName="effect-btn"
          className="mui-button-font"
          tip="Insights"
          routepath="/insights"
        />
        <Typography
          style={{
            fontSize: "24px",
            fontWeight: "600",
            marginTop: "-42px",
            marginLeft: "40px",
          }}
        >
          Insights
        </Typography>
      </div>
      <div className="body-container mt-50">
        <Grid container spacing={3}>
          {tileData.map((tile, index) => (
            <Grid item md={3} xs={12} spacing={4} sm={6} key={index}>
              <GridListTile
                md={4}
                style={{
                  height: "189px",
                  listStyleType: "none",
                  borderRadius: "50%",
                }}
                classes={{
                  tile: classes.tile,
                }}
                key={tile.img}
              >
                <div className="container">
                  <img
                    style={{ width: "100%" }}
                    src={tile.img}
                    alt={tile.title}
                  />
                  <Checkbox
                    icon={<CircleUnchecked />}
                    checkedIcon={<CircleChecked style={{ color: "white" }} />}
                    onChange={(e) => {
                      handleMenu(e, tile.slug_type, index);
                    }}
                    className={classes.checkboxPosition}
                    inputProps={{ "aria-label": "secondary checkbox" }}
                  />
                </div>
                <GridListTileBar
                  title={tile.title}
                  classes={{
                    root: classes.titleBarRoot,
                    title: classes.titleBarTitle,
                  }}
                  titlePosition="bottom"
                ></GridListTileBar>
              </GridListTile>
            </Grid>
          ))}
        </Grid>
      </div>
      <div className="mt-30">
        <Infoview InfoviewData={InfoviewData} />
      </div>
      {megaviewData.data && megaviewData.data.length > 0 && (
        <div className="mt-30 body-container">
          <h2 className="title-header ttc">
            {" "}
            <span className="red-line3letter">Lat</span>est Podcast
          </h2>
          <div className="mt-50"></div>
          <Megaview changeAccordianText MegaviewData={megaviewData.data} />
        </div>
      )}
      <div className="mt-30 body-container">
        <h2 className="title-header">
          {" "}
          <span className="red-line3letter">Sub</span>scribe to The Digiryte
          Podcast series
        </h2>
      </div>
      {articleData && articleData.length > 0 && (
        <React.Fragment>
          <div className="mt-30 body-container ttc">
            <div className="title-header">
              <span className="red-line3letter"> Rec</span>ent Digiryte podcasts
            </div>
          </div>
          <div className="mt-50">
            <Miniview miniviewData={articleData} />
          </div>
        </React.Fragment>
      )}
      {articleRawData.has_more && (
        <div style={{ width: "100%", textAlign: "center" }}>
          <div
            onMouseEnter={toggleHover}
            onMouseLeave={toggleHover}
            className="mt-60"
            role="presentation"
            onClick={() => {
              setPage(page + 1);
              setLimit(limit + 6);
            }}
            style={{ display: "inline-block" }}
          >
            <Button
              icon={
                <img
                  className="effect-btn-svgicon"
                  src={!isHover ? RightArrow : RightArrowWhite}
                  alt="MORE ARTICLES"
                />
              }
              customClassName="info-view-resize-btn"
              color="primary"
              variant="outlined"
              label="LOAD MORE"
              tip="LOAD MORE"
            />
          </div>
        </div>
      )}
      <div className="mt-70"></div>
      <Box BoxData={BoxData} />
      <div>
        <TwoColumnContactInfo
          TwoColumnContactInfoData={TwoColumnContactInfoData}
        />
      </div>
    </Layout>
  );
};

export default Podcast;
