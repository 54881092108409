import Card from "@material-ui/core/Card";
import { Link } from "gatsby";
import React from "react";
import WhitePaperFooter from "../WhitePaperFooter";
import "./MiniCard.scss";

const Minicard = (props) => {
  const CardSection = ({ isLandingPage }) => {
    return (
      <>
        <div className="card-content">
          <img
            className="miniview-image"
            src={props.MiniviewImage}
            alt={props.imgAlt}
          />
          <div className={`card-overlay ${isLandingPage ? "no-hover" : ""}`}>
            <div className="mini-title-and-content-area">
              <div
                className="mini-view-title"
                dangerouslySetInnerHTML={{
                  __html: `${props.MiniviewHeading}`,
                }}
              />
              <div
                className="mini-view-content"
                dangerouslySetInnerHTML={{
                  __html: `${props.MiniviewContent}`,
                }}
              />
            </div>
          </div>
        </div>
      </>
    );
  };
  return (
    <Card className="card-area appsgridshadow">
      {props.WhitePaperFooterLabel === "Client Brief" ? (
        <div className="card-content-without-pointer">
          <img
            className="miniview-image"
            src={props.MiniviewImage}
            alt={props.MiniviewImageAlt}
          />
          <div className="card-overlay">
            <div className="mini-title-and-content-area">
              <div
                className="mini-view-title"
                dangerouslySetInnerHTML={{ __html: `${props.MiniviewHeading}` }}
              />
              <div
                className="mini-view-content"
                dangerouslySetInnerHTML={{ __html: `${props.MiniviewContent}` }}
              />
            </div>
          </div>
        </div>
      ) : (
        <>
          {props.isLandingPage ? (
            <div style={{ textDecoration: "none", color: "black" }}>
              <CardSection isLandingPage={props.isLandingPage} />
            </div>
          ) : (
            <Link
              style={{ textDecoration: "none", color: "black" }}
              to={`/${props.Type}/${props.Slug}`}
            >
              <CardSection />
            </Link>
          )}
        </>
      )}
      <WhitePaperFooter
        gatedContent={props.GatedContent}
        navigateComponent={props.navigateComponent}
        WhitePaperLabel={props.WhitePaperFooterLabel}
        slug={props.Slug}
        type={props.Type}
        SlugType={props.SlugType}
        isLandingPage={props.isLandingPage}
      />
    </Card>
  );
};

export default Minicard;
