/*eslint-disable */
import { Grid, Tooltip } from "@material-ui/core";
import { Link } from "gatsby";
import React, { useEffect, useRef, useState } from "react";
import "./WhitePaperFooter.scss";

const WhitePaperFooter = (props) => {
  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Toggle share component if clicked outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setshareComponent(false);
        }
      }

      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }
  const [shareComponent, setshareComponent] = useState(false);
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);
  return (
    <Grid className="white-paper" onMouseLeave={() => setshareComponent(false)}>
      <Grid item className="white-paper-flex1" md={6}>
        {props.WhitePaperLabel}
      </Grid>
      {!props.isLandingPage && (
        <Grid item className="white-paper-flex2" md={6}>
          {props.download === "no"
            ? ""
            : props.type !== "article" &&
              props.WhitePaperLabel !== "Client Brief" && (
                // props.gatedContent === 1 &&
                <Link target="_blank" to={`/${props.type}/${props.slug}#gated`}>
                  <Tooltip title="Download" placement="bottom">
                    <div className="icon-main download-icon"></div>
                  </Tooltip>
                </Link>
              )}
          {props.WhitePaperLabel != "Client Brief" && (
            <Link target="_blank" to={`/${props.type}/${props.slug}/`}>
              <Tooltip title="Preview" placement="bottom">
                <div className="icon-main preview-icon"></div>
              </Tooltip>
            </Link>
          )}
          {/* {props.WhitePaperLabel != "BRIEF" && (
          <div onMouseEnter={() => setshareComponent(!shareComponent)}>
            <Tooltip title="Share" placement="bottom">
              <div className="icon-main share-icon"></div>
            </Tooltip>
          </div>
        )} */}
          {/* {shareComponent && (
          <div ref={wrapperRef}>
            <Share clickOnOptions={() => setshareComponent(!shareComponent)} />
          </div>
        )} */}
        </Grid>
      )}
    </Grid>
  );
};

export default WhitePaperFooter;
/*eslint-enable */
