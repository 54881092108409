import { Card, Grid } from "@material-ui/core";
import React from "react";
import quote from "../../assets/Images/WhatWeDo/quote.svg";
import Minicard from "../../components/MiniCard";
import MiniCardWithBgImg from "../../components/MiniCardWithBgImg";
import "./MiniView.scss";

const Miniview = (props) => {
  return (
    <div className="body-container">
      <Grid container direction="row" spacing={3}>
        {props.miniviewData &&
          props.miniviewData.map((item, index) => (
            <Grid
              key={index}
              item
              xs={12}
              sm={6}
              md={4}
              className="card crd-spc"
            >
              {/* <Link style={{textDecoration:'none',color:'black'}} to={`/${item.type}/${item.slug}`} > */}

              <Minicard
                MiniviewHeading={item.meta_title ? item.meta_title : item.title}
                MiniviewContent={
                  item.meta_description
                    ? item.meta_description
                    : item.primary_description_1
                }
                MiniviewImage={item.og_image}
                MiniviewImageAlt={item.ogImageAlt}
                WhitePaperFooterLabel={item.type}
                Slug={item.slug}
                Type={item.slug_type}
                SlugType={item.slug_type}
                GatedContent={item.gated_content}
                navigateComponent={props.navigateComponent}
                isLandingPage={props.isLandingPage}
              />
              {/* </Link> */}
            </Grid>
          ))}

        {props.quotedMiniCardData && props.miniviewData.length % 3 === 1 && (
          <React.Fragment>
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              style={{ position: "relative" }}
              className="card crd-spc"
            >
              <div className="plain-title quote-star-what1">
                <img alt="" src={quote} />
              </div>
              <Card className="card-area p-20 appsgridshadow">
                <div className="quoted-title-area">
                  {props.quotedMiniCardData.title}
                </div>
                <div className="df">
                  <div className="quoted-5-text1">5.0</div>
                  <div className="quoted-5-star1">★★★★★</div>
                </div>
                <div className="quoted-heading-area">
                  {props.quotedMiniCardData.heading}
                </div>
                <div className="quoted-content-area">
                  {props.quotedMiniCardData.content}
                </div>
                <div className="quoted-footer-area">
                  <div className="quoted-minicard-black-line"></div>
                  {props.quotedMiniCardData.footerContent}
                </div>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} className="card crd-spc" md={4}>
              {" "}
              <Card className="mini-card-bg-hght appsgridshadow">
                <MiniCardWithBgImg
                  MiniCardBgImg={props.miniviewData2.image}
                  MiniCardBgImgId="100"
                  service="clientStory"
                  title={props.miniviewData2.title}
                  subtitle={props.miniviewData2.subTitle}
                  variant={props.miniviewData2.variant}
                  customClassName="three-mini-card-btn mt-20"
                  cardCustomClass={props.miniviewData2.customClass}
                  label={props.miniviewData2.label}
                  route={props.miniviewData2.route}
                  tip={props.miniviewData2.tip}
                  tooltip={props.miniviewData2.tooltip}
                />
              </Card>
            </Grid>
          </React.Fragment>
        )}

        {props.miniviewData2 && props.miniviewData.length % 3 === 2 && (
          <Grid item xs={12} className="card crd-spc" sm={6} md={4}>
            {" "}
            <Card className="mini-card-bg-hght appsgridshadow">
              <MiniCardWithBgImg
                MiniCardBgImg={props.miniviewData2.image}
                MiniCardBgImgId="100"
                service="clientStory"
                title={props.miniviewData2.title}
                subtitle={props.miniviewData2.subTitle}
                variant={props.miniviewData2.variant}
                customClassName="three-mini-card-btn mt-20"
                cardCustomClass={props.miniviewData2.customClass}
                label={props.miniviewData2.label}
                route={props.miniviewData2.route}
                tip={props.miniviewData2.tip}
                tooltip={props.miniviewData2.tooltip}
              />
            </Card>
          </Grid>
        )}

        {props.miniviewData2 &&
          props.component === "whoWeWorkWith" &&
          props.miniviewData.length % 3 === 1 && (
            <Grid item xs={12} sm={6} md={4} className="card crd-spc">
              {" "}
              <Card className="mini-card-bg-hght appsgridshadow">
                <MiniCardWithBgImg
                  MiniCardBgImg={props.miniviewData2.image}
                  MiniCardBgImgId="100"
                  service="clientStory"
                  title={props.miniviewData2.title}
                  subtitle={props.miniviewData2.subTitle}
                  variant={props.miniviewData2.variant}
                  customClassName="three-mini-card-btn mt-20"
                  cardCustomClass={props.miniviewData2.customClass}
                  label={props.miniviewData2.label}
                  route={props.miniviewData2.route}
                  tip={props.miniviewData2.tip}
                  tooltip={props.miniviewData2.tooltip}
                />
              </Card>
            </Grid>
          )}
      </Grid>
    </div>
  );
};

export default Miniview;
